import React from "react";
import { colour } from "../../global/theme";

type Props = {
  height: number;
  width: number;
  lineFill?: string;
  backgroundFill?: string;
};
const SuccessIcon = ({
  height,
  width,
  lineFill = colour.white,
  backgroundFill = colour.success,
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>ErrorIcon</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity="0.930777414"
      >
        <g id="Artboard" transform="translate(-24.000000, -94.000000)">
          <g id="Group-Copy-2" transform="translate(24.000000, 89.000000)">
            <g id="Group-12-Copy-2" transform="translate(0.000000, 5.000000)">
              <circle
                id="Oval-Copy-2"
                fill={backgroundFill}
                cx="7"
                cy="7"
                r="7"
              ></circle>
              <path
                d="M3.86981031,6.574687 L5.8681955,10.3563415 C5.9456067,10.5028309 6.12711406,10.5588299 6.27360353,10.4814187 C6.31476474,10.4596674 6.35029888,10.428635 6.37739404,10.3907771 L10.8508598,4.14036684 C10.883003,4.0954558 10.8726526,4.03299102 10.8277416,4.00084786 C10.7906587,3.97430736 10.7403173,3.97621602 10.7053505,4.00548825 L6.38513514,7.62213066 C6.21348585,7.76582585 5.96724334,7.77791923 5.78233981,7.65173496 L4.01459287,6.44536612 C3.96897465,6.41423474 3.90675681,6.42597865 3.87562544,6.47159686 C3.85472413,6.50222449 3.85248593,6.54190313 3.86981031,6.574687 Z"
                id="Path"
                fill={lineFill}
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export { SuccessIcon };
