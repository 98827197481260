import { useRouter } from "next/router";
import Image from "next/image";
import ProfileIcon from "../../assets/profile_icon.svg";

import { useGlobalState, useLogOut } from "../../global/hooks";

import styles from "./Navbar.module.css";
import { Routes } from "../../global/constants";

const PROFILE_ICON_SIZE = 34;

const ProposalNavbar = () => {
  const globalState = useGlobalState();
  const router = useRouter();
  const { logout } = useLogOut();

  const onProfileClick = () => {
    // TODO: Link to profile page
  };

  const onMyProposalClick = () => {
    router.push(Routes.Proposals);
  };

  return (
    <div className={styles.navBar}>
      <div className={styles.container}>
        <div className={styles.domainName} onClick={onMyProposalClick}>
          Blockposal.io
        </div>
        <button className={styles.navItem} onClick={onMyProposalClick}>
          Home
        </button>
        <div className={styles.profileContainer} onClick={onProfileClick}>
          {/* <Image
            src={ProfileIcon}
            height={PROFILE_ICON_SIZE}
            width={PROFILE_ICON_SIZE}
          /> */}
          <button className={styles.navItem} onClick={logout}>
            Sign out
          </button>
        </div>
      </div>
      <div className={styles.line} />
    </div>
  );
};

export { ProposalNavbar };
