import { useEffect, useState } from "react";
import { Backend, RawProposalData } from "../../clients/backend";
import { ProposalFileProps } from "../../components/proposalCard/ProposalCard.entities";
import { TOKEN_STORAGE_KEY, USER_ID_STORAGE_KEY } from "../../global/constants";
import { useGlobalState, useLogOut } from "../../global/hooks";

export type ArtifactsLoaction = {
  decentralisedMetadataLocation: string;
  decentralisedZipfileLocation: string;
  zipfileLocation: string;
};

export enum ProposalStatus {
  Committed = "committed",
  Confirmed = "confirmed",
  Pending = "pending",
}

export type Proposal = {
  id: string;
  name: string;
  creator: string;
  isActive: boolean;
  toggleIsActive?: (index: number) => void;
  description?: string;
  blockChainTransactionid?: string;
  metadata?: object;
  files: ProposalFileProps[];
  dateCreated: string;
  hashedContents?: string;
  tokenId?: string;
  artifactLocations?: ArtifactsLoaction;
  status: ProposalStatus;
};

const mapProposalStatus = (status: string): ProposalStatus => {
  if (status === "confirmed") return ProposalStatus.Confirmed;
  if (status === "committed") return ProposalStatus.Committed;
  if (status === "pending") return ProposalStatus.Pending;
  return ProposalStatus.Pending;
};

const mapProposalToType = (
  proposalData: RawProposalData,
  index: number,
  activeIndex: number
): Proposal => {
  return {
    id: proposalData.uid,
    name: proposalData.name,
    creator: proposalData.creator_name,
    description: proposalData.description,
    blockChainTransactionid: proposalData.blockchain_transaction_id,
    isActive: index === activeIndex ? true : false,
    dateCreated: proposalData.date_created,
    hashedContents: proposalData.hashed_contents,
    tokenId: proposalData.token_id,
    metadata: proposalData.metadata,
    artifactLocations: {
      decentralisedMetadataLocation:
        proposalData.artifact_locations.decentralised_metadata_location,
      decentralisedZipfileLocation:
        proposalData.artifact_locations.decentralised_metadata_location,
      zipfileLocation: proposalData.artifact_locations.zipfile_location,
    },
    status: mapProposalStatus(proposalData.status),
    files: proposalData.files.map((file) => {
      return {
        uid: file.uid,
        name: file.name,
        type: file.type,
        dateCreated: file.date_created,
        publicUrl: file.public_url,
      };
    }),
  };
};

type ActiveProposal = {
  current: number;
  previous: number;
};

const useProposalData = () => {
  const [activeIndex, setActiveIndex] = useState<ActiveProposal>({
    current: 0,
    previous: 0,
  });
  const [proposals, setProposals] = useState<Proposal[]>([]);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const globalState = useGlobalState();
  const { logout } = useLogOut();

  useEffect(() => {
    getProposals();
  }, []);

  useEffect(() => {
    if (proposals.length === 0) return;
    const proposalCopy = [...proposals];
    [activeIndex.current, activeIndex.previous].forEach((index) => {
      const proposalItem = proposals[index];
      proposalItem.isActive = index === activeIndex.current;
      proposalCopy[activeIndex.previous] = proposalItem;
    });
    setProposals(proposalCopy);
  }, [activeIndex]);

  const getProposals = async () => {
    const token =
      globalState.state.token ?? localStorage.getItem(TOKEN_STORAGE_KEY);
    const userId =
      globalState.state.userId ?? localStorage.getItem(USER_ID_STORAGE_KEY);
    if (!token || !userId) {
      logout();
      return;
    }
    setIsLoading(true);
    const response = await Backend.getProposalsByUser({ token, userId });
    setIsLoading(false);
    if (!response.success) {
      setHasError(true);
      return;
    }
    const typedProposals = response.payload!.proposals.map((proposal, index) =>
      mapProposalToType(proposal, index, activeIndex.current)
    );
    setProposals(typedProposals);
  };

  const toggleProposalIsActive = (index: number) => {
    setActiveIndex({ current: index, previous: activeIndex.current });
  };

  const refetch = () => {
    getProposals();
  };

  return { proposals, isLoading, hasError, toggleProposalIsActive, refetch };
};

export { useProposalData };
