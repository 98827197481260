import React from "react";
import styles from "./TextInput.module.css";

type Props = {
  value: string;
  onTextChange: (text: string) => void;
  isMultiline?: boolean;
  disabled?: boolean;
  placeholder?: string;
};

const TextInput = ({
  value,
  onTextChange,
  isMultiline,
  disabled,
  placeholder,
}: Props) => {
  const onChange = (event: React.FormEvent<HTMLInputElement>) => {
    onTextChange(event.currentTarget.value);
  };
  const onMulitlineChange = (event: any) => {
    onTextChange(event.currentTarget.value);
  };

  const renderMultilineInput = () => (
    <textarea
      placeholder={placeholder}
      disabled={disabled}
      className={[styles.input, styles.multiline].join(" ")}
      value={value}
      onChange={onMulitlineChange}
    />
  );

  const renderInput = () => (
    <input
      placeholder={placeholder}
      disabled={disabled}
      className={styles.input}
      value={value}
      onChange={onChange}
    />
  );

  return isMultiline ? renderMultilineInput() : renderInput();
};

export { TextInput };
