import React from "react";
import styles from "./StatusMessage.module.css";
import BaseText, { FontWeight, TextSize } from "../baseText";
import { WarningIconOutline, ErrorIcon, SuccessIcon } from "../icons";
import { colour } from "../../global/theme";

export enum Status {
  Error = "error",
  Warniing = "warning",
  Success = "success,",
}

type Props = {
  status: Status;
  text: string;
};

const ICON_SIZE = 16;
const WARNING_ICON_SIZE = 18;

const renderIconError = (status: Status) => {
  switch (status) {
    case Status.Error:
      return <ErrorIcon height={ICON_SIZE} width={ICON_SIZE} />;
    case Status.Warniing:
      return (
        <WarningIconOutline
          height={WARNING_ICON_SIZE}
          width={WARNING_ICON_SIZE}
          lineFill={colour.warning}
        />
      );
    case Status.Success:
      return <SuccessIcon height={ICON_SIZE} width={ICON_SIZE} />;
  }
};

const textColourMap = {
  [Status.Error]: colour.error,
  [Status.Warniing]: colour.warning,
  [Status.Success]: colour.success,
};

const StatusMessageView = ({ status, text }: Props) => {
  const baseIconClass = styles.icon;
  const statusSIconStyle =
    status === Status.Warniing
      ? [baseIconClass, styles.warningIcon]
      : [baseIconClass];

  return (
    <div className={styles.container}>
      <div className={statusSIconStyle.join(" ")}>
        {renderIconError(status)}
      </div>
      <BaseText
        text={text}
        size={TextSize.Body}
        fontWeight={FontWeight.SemiBold}
        className={styles.errorText}
        style={{ color: textColourMap[status] }}
      />
    </div>
  );
};

export { StatusMessageView };
