import React from "react";
import styles from "./BackgroundOverlay.module.css";

type Props = {
  show: boolean;
};
const BackgroundOverlay = ({ show }: Props) => {
  return show ? (
    <div className={styles.container} />
  ) : (
    <div className={styles.hiddenView} />
  );
};

export { BackgroundOverlay };
