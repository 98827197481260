import { PageTitleProps } from "./PageTitle.entities"

import styles from "./PageTitle.module.css"


const PageTitle = (props: PageTitleProps) => {
    return <h1 className={styles.title}>{props.title}</h1>
}

export default PageTitle

