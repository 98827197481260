import { useEffect, useState } from "react";
import { TOKEN_STORAGE_KEY } from "../../global/constants";
import { Backend } from "../../clients/backend";
import { ProposalFile } from "../../components/createProposalInputFiles/createProposalInputFiles.view";
import { useGlobalState } from "../../global/hooks";
import { ProposalStatus } from "../useProposalData";
import { MetadataArray } from "../../components/createProposalMetadata/CreateProposalMetadata";

export enum SubmissionState {
  None = "none",
  ProposalDetailsCreated = "proposalDetailsCreated",
  UploadedFiles = "uploadedFiles",
  UploadedThumbnail = "uploadedThumbnail",
  ProposalCommitted = "proposalCommitted",
  ProposalConfirmed = "proposalConfirmed",
}

type CreateProposalResponse<T = null> = {
  success: boolean;
  error?: string;
  data?: T;
};

type CreateProposalDetailsProps = {
  creatorName: string;
  proposalName: string;
  description: string;
  metadata: MetadataArray;
};

type SubmitFilesProps = {
  files: ProposalFile[];
};

type UseCreateProposalData = {
  createProposalDetails: ({
    creatorName,
    proposalName,
    description,
    metadata,
  }: CreateProposalDetailsProps) => Promise<CreateProposalResponse>;
  submitFiles: ({ files }: SubmitFilesProps) => Promise<CreateProposalResponse>;
  skipFileUpload: () => void;
  skipFileUploadReset: () => void;
  setProposalSubmissionState: (status: ProposalStatus) => void;
  skipThumbnailUpload: () => void;
  skipThumbnailUploadReset: () => void;
  failedFiles: ProposalFile[];
  isLoading: boolean;
  submissionState: SubmissionState;
  proposalUid?: string | null;
};

const useCreateProposalData = (): UseCreateProposalData => {
  const [submissionState, setSubmissionState] = useState<SubmissionState>(
    SubmissionState.None
  );
  const [proposalUid, setProposalUid] = useState<string | null>(null);
  const [failedFiles, setFailedFiles] = useState<ProposalFile[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const globalState = useGlobalState();

  let token = globalState.state.token;

  useEffect(() => {
    if (!token) {
      token = localStorage.getItem(TOKEN_STORAGE_KEY) ?? undefined;
    }
  }, []);

  const addFilesToProposal = async (
    files: ProposalFile[],
    proposalUid: string,
    token: string
  ) => {
    for (const file of files) {
      file.fileData.append("proposal_uid", proposalUid);
      const response = await Backend.addFileToProposal({
        token,
        formData: file.fileData,
      });
      if (!response.success) {
        setFailedFiles([...failedFiles, file]);
        break;
      }
    }
  };

  const createProposalDetails = async ({
    creatorName,
    proposalName,
    description,
    metadata,
  }: CreateProposalDetailsProps): Promise<CreateProposalResponse> => {
    if (!token) {
      return {
        success: false,
        error: "Could not find your public key",
      };
    }
    setIsLoading(true);
    const metadataBody = metadata.map((data) => {
      return {
        key: data.key,
        value: data.value,
      };
    });
    const metadataObject =
      metadataBody?.length > 0 ? Object.assign({}, metadataBody) : undefined;
    const createProposalresponse = await Backend.createProposal({
      token,
      creatorName,
      proposalName,
      description,
      metadata: metadataObject,
    });

    setIsLoading(false);
    if (!createProposalresponse.success) {
      return {
        success: false,
        error: "There was an error submitting your proposal, please try again.",
      };
    }
    setProposalUid(createProposalresponse.payload!.proposalUid);
    setSubmissionState(SubmissionState.ProposalDetailsCreated);

    return {
      success: true,
    };
  };

  const submitFiles = async ({
    files,
  }: SubmitFilesProps): Promise<CreateProposalResponse> => {
    setIsLoading(true);
    if (!proposalUid) {
      return {
        success: false,
        error: "An unexpected error occured",
      };
    }
    if (!token) {
      return {
        success: false,
        error: "Could not find your public key",
      };
    }
    await addFilesToProposal(files, proposalUid, token);
    setIsLoading(false);
    if (failedFiles.length > 0) {
      return {
        success: false,
        error: "Failed to upload files to the blockchain",
      };
    }
    //TODO: clean up
    const newSubmissionState =
      submissionState === SubmissionState.ProposalDetailsCreated
        ? SubmissionState.UploadedFiles
        : SubmissionState.UploadedThumbnail;
    setSubmissionState(newSubmissionState);

    return {
      success: true,
    };
  };

  const skipFileUpload = () =>
    setSubmissionState(SubmissionState.UploadedFiles);

  const skipFileUploadReset = () =>
    setSubmissionState(SubmissionState.ProposalDetailsCreated);

  const skipThumbnailUpload = () =>
    setSubmissionState(SubmissionState.UploadedThumbnail);

  const skipThumbnailUploadReset = () =>
    setSubmissionState(SubmissionState.UploadedFiles);

  const setProposalSubmissionState = (status: ProposalStatus) => {
    switch (status) {
      case ProposalStatus.Pending:
        setSubmissionState(SubmissionState.ProposalDetailsCreated);
        return;
      case ProposalStatus.Committed:
        setSubmissionState(SubmissionState.ProposalCommitted);
        return;
      case ProposalStatus.Confirmed:
        setSubmissionState(SubmissionState.ProposalConfirmed);
        return;
    }
  };

  return {
    createProposalDetails,
    submitFiles,
    skipFileUpload,
    skipFileUploadReset,
    setProposalSubmissionState,
    skipThumbnailUpload,
    skipThumbnailUploadReset,
    failedFiles,
    isLoading,
    submissionState,
    proposalUid,
  };
};

export { useCreateProposalData };
