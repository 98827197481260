import React from "react";
import { colour } from "../../global/theme";

type Props = {
  height: number;
  width: number;
  lineFill?: string;
  backgroundFill?: string;
};
const CloseIcon = ({
  height,
  width,
  lineFill = colour.disabled,
  backgroundFill = colour.background,
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 8"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Close Icon</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="create_proposal_screen"
          transform="translate(-154.000000, -707.000000)"
        >
          <g id="file_card" transform="translate(34.000000, 516.000000)">
            <g id="Group-5" transform="translate(22.000000, 187.000000)">
              <g id="Group-10" transform="translate(98.000000, 4.000000)">
                <circle
                  id="Oval"
                  fill={backgroundFill}
                  cx="4"
                  cy="4"
                  r="4"
                ></circle>
                <path
                  d="M4,1.6 C4.2209139,1.6 4.4,1.7790861 4.4,2 L4.4,3.6 L6,3.6 C6.2209139,3.6 6.4,3.7790861 6.4,4 C6.4,4.2209139 6.2209139,4.4 6,4.4 L4.4,4.4 L4.4,6 C4.4,6.2209139 4.2209139,6.4 4,6.4 C3.7790861,6.4 3.6,6.2209139 3.6,6 L3.6,4.4 L2,4.4 C1.7790861,4.4 1.6,4.2209139 1.6,4 C1.6,3.7790861 1.7790861,3.6 2,3.6 L3.6,3.6 L3.6,2 C3.6,1.7790861 3.7790861,1.6 4,1.6 Z"
                  id="Combined-Shape"
                  fill={lineFill}
                  transform="translate(4.000000, 4.000000) rotate(45.000000) translate(-4.000000, -4.000000) "
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export { CloseIcon };
