import React, { useRef, useState } from "react";
import FileCard from "../ffileFeed/FileCard";
import styles from "./createProposalInputFiles.module.css";
import hash from "object-hash";
import DataInputCard from "../dataInputCard";
import BaseText from "../baseText";
import { conditionalRender } from "../../utils/helpers";
import { Event } from "../../utils/helpers";
import RoundedButton, { ButtonType } from "../../roundedButton";
import {
  MAX_FILE_SIZE_MB,
  MEGATBYTES_TO_BYTES_RATIO,
  NO_OF_FILES_LIMIT,
} from "../../global/constants";

export type ProposalFile = {
  id: string;
  fileData: FormData;
};

type Props = {
  files: ProposalFile[];
  errorMessage?: string;
  addFile: (file: ProposalFile) => void;
  onDeleteFilePress?: (fileId: string) => void;
  disabled?: boolean;
  skipDisabled?: boolean;
  collapsed?: boolean;
  onSubmitClick?: () => void;
  isLoading?: boolean;
  onSkipPress: () => void;
  onSkipResetPress: () => void;
};

//TODO: Move somewhere more generic
export enum FileType {
  Generic = "generic",
  Thumbnail = "thumbnail",
}

const CreateProposalInputFiles = ({
  files,
  errorMessage,
  addFile,
  onDeleteFilePress,
  disabled,
  collapsed,
  onSubmitClick,
  isLoading,
  skipDisabled,
  onSkipPress,
  onSkipResetPress,
}: Props) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [skipped, setSkipped] = useState(false);

  const onFileChange = (event: Event<HTMLInputElement>) => {
    const file = event?.target?.files?.[0];
    if (!file) return;
    const fileSizeInBytes = file.size;
    if (fileSizeInBytes > MAX_FILE_SIZE_MB * MEGATBYTES_TO_BYTES_RATIO) {
      alert(`File too large: file size is limited to ${MAX_FILE_SIZE_MB} MB`);
      event.target.value = "";
      return;
    }
    const newFileData = new FormData();

    newFileData.append("file", file);
    newFileData.append("name", file.name);
    newFileData.append("type", FileType.Generic);

    const newFile: ProposalFile = {
      id: hash(file.name),
      fileData: newFileData,
    };

    if (files.some((existingFile) => existingFile.id === newFile.id)) {
      event.target.value = "";
      alert("A file with the same name already exists");
      event.target.value = "";
      return;
    }

    addFile(newFile);
    // Allows the same file to be uploaded
    event.target.value = "";
  };

  const onAddButtonPress = () => {
    fileInputRef.current?.click();
  };

  const onSkipButtonPress = () => {
    setSkipped(true);
    onSkipPress();
  };

  const onSkipResetButtonPress = () => {
    setSkipped(false);
    onSkipResetPress();
  };

  return (
    <DataInputCard
      title="Files"
      collapsed={collapsed}
      errorMessage={errorMessage}
    >
      <div className={styles.filesDescription}>
        <BaseText
          text="If your idea has any corresponding files we strongly recommend you
        uploading them here to make your idea more provable. (max 5 files)"
        />
      </div>
      <div className={styles.filesDescription}>
        <BaseText
          text="As well as storing the fields above in decentralised storage, the
          contents of these files will be hashed and written to the blockchain
          itself so you can prove that this idea correspons to these files"
        />
      </div>
      {conditionalRender(
        files?.length > 0,
        <div className={styles.fileFeed}>
          {files.map((file) => {
            return (
              <FileCard
                fileId={file.id}
                fileName={file.fileData?.get("name")?.toString() ?? ""}
                key={file.id}
                isEditable={!disabled}
                onDeletePress={onDeleteFilePress}
              />
            );
          })}
        </div>
      )}
      {conditionalRender(
        !disabled && files.length < NO_OF_FILES_LIMIT,
        <>
          <input
            ref={fileInputRef}
            style={{ display: "none" }}
            type="file"
            onChange={onFileChange}
          />
          <RoundedButton
            text="Add file"
            onClick={onAddButtonPress}
            type={ButtonType.Secondary}
            wide={false}
          />
        </>
      )}
      {conditionalRender(
        !disabled,
        <div className={styles.buttonContainer}>
          <div className={styles.submitButton}>
            <RoundedButton
              disabled={isLoading}
              isLoading={isLoading}
              onClick={onSubmitClick}
              text={"Submit"}
            />
          </div>
          <RoundedButton
            onClick={onSkipButtonPress}
            text={"Skip"}
            type={ButtonType.Secondary}
          />
        </div>
      )}
      {conditionalRender(
        skipped,
        <div className={styles.buttonContainer}>
          <RoundedButton
            disabled={skipDisabled}
            text={"Wait! I want to add files"}
            type={ButtonType.Secondary}
            onClick={onSkipResetButtonPress}
          />
        </div>
      )}
    </DataInputCard>
  );
};

export { CreateProposalInputFiles };
