import { useGlobalState } from "../../global/hooks";
import { useRouter } from "next/router";
import { ReactNode } from "react";

import Loading from "../loading";
import { Routes } from "../../global/constants";

interface ProtectedRouteProps {
  redirectUrl?: string;
  children: ReactNode;
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const isClient = typeof window !== "undefined" ? true : false;
  const router = useRouter();
  const globalState = useGlobalState();

  const redirectUrl = props.redirectUrl || Routes.Login;

  if (isClient && !globalState.state.isAuthenticated) {
    router.push(redirectUrl);
    return <Loading />;
  }

  return <>{props.children}</>;
};

export default ProtectedRoute;
