import React from "react";
import { colour } from "../../global/theme";

type Props = {
  height: number;
  width: number;
  lineFill?: string;
};
const WarningIconOutline = ({
  height,
  width,
  lineFill = colour.black,
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>WarningIcon</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group-9-Copy-2" transform="translate(-6.000000, -2.000000)">
          <g id="Group-13-Copy" transform="translate(6.000000, 2.000000)">
            <path
              d="M7.66533649,2.40575254 C7.90804075,2.32456832 8.18171574,2.33597265 8.42883431,2.45920828 C8.62246339,2.55576921 8.7795505,2.71252748 8.87651699,2.90595378 L8.87651699,2.90595378 L14.4640633,14.05185 C14.5878165,14.2987098 14.5997943,14.5723602 14.5191188,14.8152341 C14.4384434,15.0581079 14.2651147,15.2702051 14.0182549,15.3939584 L14.0182549,15.3939584 L2.4242087,15.5 C2.14806633,15.5 1.89806633,15.3880712 1.71710192,15.2071068 C1.53613752,15.0261424 1.4242087,14.7761424 1.4242087,14.5 L1.4242087,14.5 L7.0876631,2.90782812 C7.21089872,2.66070954 7.42263224,2.48693677 7.66533649,2.40575254 Z"
              id="Rectangle"
              stroke={lineFill}
            ></path>
            <g
              id="Group-11-Copy-3"
              transform="translate(7.000000, 6.000000)"
              fill={lineFill}
            >
              <path
                d="M0.814843679,0 L1.1854709,0 C1.59968446,-1.87112099e-16 1.9354709,0.335786438 1.9354709,0.75 C1.9354709,0.770613769 1.93462104,0.791218774 1.9329235,0.811762527 L1.63509359,4.41611206 C1.60782785,4.74608321 1.33206436,5 1.00096864,5 C0.669839126,5 0.394014512,4.74612497 0.366618995,4.41613067 L0.0674149009,0.812050154 C0.0331455472,0.399256638 0.340000009,0.0368405756 0.752793525,0.00257122185 C0.773432539,0.000857809157 0.794133666,3.80436775e-18 0.814843679,0 Z"
                id="Rectangle"
              ></path>
              <circle id="Oval" cx="1" cy="7" r="1"></circle>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export { WarningIconOutline };
