import React from "react";
import { colour } from "../../global/theme";
import { conditionalRender } from "../../utils/helpers";
import BaseText from "../baseText";
import { FontWeight, TextSize } from "../baseText";
import styles from "./dataInputCard.module.css";
import Image from "next/image";
import ErrorIcon from "../../assets/error_icon.svg";

const ERROR_ICON_SIZE = 16;

type Props = {
  collapsed?: boolean;
  children: React.ReactNode;
  title: string;
  errorMessage?: string;
};

const renderIconError = () => (
  <div className={styles.errorIcon}>
    <Image src={ErrorIcon} height={ERROR_ICON_SIZE} width={ERROR_ICON_SIZE} />
  </div>
);

const DataInputCard = ({ children, collapsed, title, errorMessage }: Props) => {
  const renderTitle = () => (
    <>
      <BaseText
        text={title}
        size={TextSize.Heading}
        fontWeight={FontWeight.Bold}
      />
      {conditionalRender(
        !!errorMessage,
        <div className={styles.errorRow}>
          {renderIconError()}
          <BaseText
            text={errorMessage!}
            size={TextSize.Body}
            className={styles.errorText}
            style={{ color: colour.error }}
          />
        </div>
      )}
    </>
  );

  const baseStyle = styles.container;
  if (collapsed) {
    return (
      <div className={[baseStyle, styles.disabled].join(" ")}>
        {renderTitle()}
      </div>
    );
  }
  return (
    <div className={[baseStyle, styles.enabled].join(" ")}>
      <div className={styles.title}>{renderTitle()}</div>
      {children}
    </div>
  );
};

export { DataInputCard };
