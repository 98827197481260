import React from "react";
import styles from "./FileCard.module.css";
import FileIcon from "../../../assets/file_icon.svg";
import DownloadIcon from "../../../assets/download_icon.svg";
import CloseIcon from "../../../assets/close_icon.svg";
import Image from "next/image";

type Props = {
  fileId: string;
  fileName: string;
  isEditable?: boolean;
  onDownloadPress?: (id: string) => void;
  onDeletePress?: (id: string) => void;
};

const FILE_ICON_SIZE = 40;
const DOWNLOAD_BUTTON_HEIGHT = 12;
const DOWNLOAD_BUTTON_WIDTH = 14;

//TODO: Refactor to handle uploading files and displaying response files
const FileCard = ({
  fileId,
  fileName,
  isEditable,
  onDownloadPress,
  onDeletePress,
}: Props) => {
  const renderTopRightIcon = () => {
    if (isEditable) {
      return (
        <Image
          onClick={() => onDeletePress?.(fileId)}
          className={styles.button}
          src={CloseIcon}
          height={16}
          width={16}
        />
      );
    }
    return null;
    // TODO: Remove once ability to download files is available
    return (
      <Image
        onClick={() => onDownloadPress?.(fileId)}
        className={styles.button}
        src={DownloadIcon}
        height={DOWNLOAD_BUTTON_HEIGHT}
        width={DOWNLOAD_BUTTON_WIDTH}
      />
    );
  };
  return (
    <div className={styles.fileCard} key={fileId}>
      <div className={styles.cardTopRow}>
        <div className={styles.fileIcon}>
          <Image
            src={FileIcon}
            height={FILE_ICON_SIZE}
            width={FILE_ICON_SIZE}
          />
        </div>
        {renderTopRightIcon()}
      </div>
      <div className={styles.fileName}>{fileName}</div>
    </div>
  );
};
export { FileCard };
