import React from "react";
import BaseText from "../components/baseText";
import BaseButton from "../components/buttons/baseButton";
import { colour } from "../global/theme";
import styles from "./RoundedButton.module.css";

export enum ButtonType {
  Primary = "primary",
  Secondary = "secondary",
}

type Props = {
  onClick?: () => void;
  text: string;
  type?: ButtonType;
  isLoading?: boolean;
  wide?: boolean;
  disabled?: boolean;
};

const textColourMap = {
  [ButtonType.Primary]: {
    text: colour.white,
    background: colour.interactive,
    loader: colour.white,
  },
  [ButtonType.Secondary]: {
    text: colour.black,
    background: colour.background,
    loader: colour.interactive,
  },
};

const RoundedButton = ({
  text,
  onClick,
  type = ButtonType.Primary,
  isLoading,
  wide = true,
  disabled,
}: Props) => {
  const colours = textColourMap[type];
  const containerClass = wide ? styles.wide : styles.narrow;
  const border =
    type === ButtonType.Secondary
      ? `solid 0.5px ${colour.disabled}`
      : undefined;

  return (
    <BaseButton
      disabled={disabled}
      className={[styles.base, containerClass].join(" ")}
      onClick={onClick}
      style={{
        backgroundColor: colours.background,
        border,
      }}
      isLoading={isLoading}
      loaderColour={textColourMap[type].loader}
    >
      <BaseText style={{ color: colours.text }} text={text} />
    </BaseButton>
  );
};

export { RoundedButton };
