import React, { useEffect, useRef } from "react";
import { mergeArray } from "../../utils/helpers";
import styles from "./CommitSuccessView.module.css";
import * as LottieLock from "../../assets/lottie_lock.json";
import { LottieRefCurrentProps } from "lottie-react";
import BaseText, { FontWeight, TextSize } from "../baseText";
import dynamic from "next/dynamic";
const Lottie = dynamic(() => import("lottie-react"), { ssr: false });

type Props = {
  show: boolean;
  onAnimationEnd: () => void;
};

const CommitSuccessView = ({ show, onAnimationEnd }: Props) => {
  const lottieRef = useRef<LottieRefCurrentProps | null>(null);

  useEffect(() => {
    if (!show || !lottieRef.current) return;
    lottieRef?.current?.play();
    lottieRef?.current?.setSpeed(0.7);
    console.log("PLAY");
  }, [show]);

  const getSuccessClass = () => {
    if (show) {
      return mergeArray([styles.container, styles.containerShow]);
    }
    return styles.container;
  };

  const onComplere = () => {
    console.log("COMPLETE");
    lottieRef.current?.stop();
    onAnimationEnd();
  };

  return (
    <div className={getSuccessClass()}>
      <div className={styles.lottieContainer}>
        <Lottie
          lottieRef={lottieRef}
          autoPlay={false}
          loop={false}
          animationData={LottieLock}
          onComplete={onComplere}
        />
      </div>
      <BaseText
        text="Committed to the blockchain"
        size={TextSize.Heading}
        fontWeight={FontWeight.SemiBold}
      />
    </div>
  );
};

export { CommitSuccessView };
