import { useEffect, useState } from "react";
import { Backend } from "../../clients/backend";
import MetamaskConnectUtils from "../../components/metamaskConnect/MetamaskConnect.utils";
import {
  ETHEREUM_PUBLIC_KEY_STORAGE_KEY,
  TOKEN_STORAGE_KEY,
} from "../../global/constants";
import { useGlobalState } from "../../global/hooks";
import { getContractAddress } from "../../utils/blockchainConfig";

enum CommitStatus {
  Idle = "idle",
  None = "none",
  BackendCommitted = "backendCommitted",
  BlockchainCommitted = "blockchainCommitted",
  BackendConfirmed = "backendConfirmed",
}

type CommitProposalProps = {
  proposalUid: string;
  metadataLocation?: string;
  proposalHash?: string;
};

type CommitProposalResponseData = {
  proposalUid?: string;
  metadataLocation?: string;
  proposalHash?: string;
  transactionHash?: string;
  state: CommitStatus;
};

type CommitProposalData = {
  commitProposal: ({
    proposalUid,
    metadataLocation,
    proposalHash,
  }: CommitProposalProps) => void;
  isLoading: boolean;
  errorMessage: string | undefined;
  status: CommitStatus;
};

const emptyCommitProposalData: CommitProposalResponseData = {
  state: CommitStatus.Idle,
};

type Props = {
  proposalId?: string;
  onSuccess: () => void;
};
const useCommitProposalData = ({
  proposalId,
  onSuccess,
}: Props): CommitProposalData => {
  const [commitProposalData, setCommitProposalData] =
    useState<CommitProposalResponseData>(emptyCommitProposalData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const globalState = useGlobalState();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  let token = globalState.state.token;

  useEffect(() => {
    if (!token) {
      token = localStorage.getItem(TOKEN_STORAGE_KEY) ?? undefined;
    }
  }, []);

  useEffect(() => {
    setErrorMessage(undefined);
  }, [proposalId]);

  const commitProposalToBackend = async () => {
    setIsLoading(true);
    if (!commitProposalData?.proposalUid) {
      return {
        success: false,
        error: "An unexpected error occured",
      };
    }
    if (!token) {
      return {
        success: false,
        error: "Could not find your public key",
      };
    }
    const response = await Backend.commitProposal({
      token,
      proposalUid: commitProposalData.proposalUid,
    });

    setIsLoading(false);
    if (!response.success) {
      setErrorMessage(
        "Failed to commit your proposal to the blockchain, please try again"
      );
    }
    setCommitProposalData({
      ...commitProposalData,
      proposalHash: response.payload?.proposalHash,
      metadataLocation: response.payload?.metadataLocation,
      state: CommitStatus.BackendCommitted,
    });
  };

  const commitProposalToBlockchain = async () => {
    const ethPublicKey = localStorage.getItem(ETHEREUM_PUBLIC_KEY_STORAGE_KEY);
    const metadataLocation = commitProposalData?.metadataLocation;
    const proposalHash = commitProposalData?.proposalHash;
    if (!ethPublicKey) {
      return {
        success: false,
        error: "No ethereum key found",
      };
    }

    if (!metadataLocation || !proposalHash) {
      return {
        success: false,
        error: "There was an error committing your proposal",
      };
    }

    setIsLoading(true);
    const result = await MetamaskConnectUtils.sendTransaction(
      metadataLocation,
      proposalHash
    );

    setIsLoading(false);
    if (!result.success) {
      setErrorMessage(
        "There was an issue committing your proposal to the Blockchain."
      );
    }
    setCommitProposalData({
      ...commitProposalData,
      transactionHash: result.payload?.transactionHash,
      state: CommitStatus.BlockchainCommitted,
    });
  };

  const confirmProposalToBackend = async () => {
    if (!token) {
      return {
        success: false,
        error: "Could not find your public key",
      };
    }
    const proposalUid = commitProposalData?.proposalUid;
    const transactionHash = commitProposalData?.transactionHash;
    if (!proposalUid || !transactionHash) {
      return {
        success: false,
        error: "An unexpected error occured",
      };
    }

    const contractAddress = getContractAddress();
    if (!contractAddress) {
      return {
        success: false,
        error: "An unexpected error occured: could not find contract address",
      };
    }
    const result = await Backend.confirmProposal({
      proposalUid: proposalUid,
      token,
      blockchainTransactionId: transactionHash,
      contractAddress,
    });

    setIsLoading(false);
    if (!result.success) {
      setErrorMessage(
        "There was an issue confirming your proposal, please try again"
      );
    }

    setCommitProposalData({
      ...commitProposalData,
      state: CommitStatus.BackendConfirmed,
    });
    // TODO: send back proposal
  };

  useEffect(() => {
    if (errorMessage) return;
    switch (commitProposalData?.state) {
      case CommitStatus.Idle:
        break;
      case CommitStatus.None:
        commitProposalToBackend();
        break;
      case CommitStatus.BackendCommitted:
        commitProposalToBlockchain();
        break;
      case CommitStatus.BlockchainCommitted:
        confirmProposalToBackend();
        break;
      case CommitStatus.BackendConfirmed:
        onSuccess();
        setErrorMessage(undefined);
        break;
    }
  }, [commitProposalData]);

  const commitProposal = ({
    proposalUid,
    metadataLocation,
    proposalHash,
  }: CommitProposalProps) => {
    setErrorMessage(undefined);
    if (proposalHash && metadataLocation) {
      setCommitProposalData({
        proposalUid: proposalUid,
        proposalHash,
        metadataLocation,
        state: CommitStatus.BackendCommitted,
      });
      return;
    }
    setCommitProposalData({
      proposalUid: proposalUid,
      state: CommitStatus.None,
    });
  };

  return {
    isLoading,
    errorMessage,
    commitProposal,
    status: commitProposalData?.state,
  };
};

export { useCommitProposalData };
