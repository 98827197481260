import React from "react";
import { colour } from "../../global/theme";

type Props = {
  height: number;
  width: number;
  lineFill?: string;
  backgroundFill?: string;
};
const ErrorIcon = ({
  height,
  width,
  lineFill = colour.white,
  backgroundFill = colour.error,
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Group 12</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="create_proposal_screen"
          transform="translate(-51.000000, -699.000000)"
        >
          <g id="Group-12" transform="translate(51.000000, 699.000000)">
            <circle
              id="Oval"
              fill={backgroundFill}
              cx="7"
              cy="7"
              r="7"
            ></circle>
            <g
              id="Group-11"
              transform="translate(6.250000, 3.250000)"
              fill={lineFill}
            >
              <path
                d="M0.75,0 C1.14048673,-7.17312483e-17 1.45703877,0.316552038 1.45703877,0.707038766 C1.45703877,0.720933916 1.45662915,0.734826047 1.45581046,0.748697058 L1.21602457,4.81135495 C1.20148577,5.05768376 0.997483976,5.25 0.750726478,5.25 C0.503950415,5.25 0.299915246,5.05770543 0.285307412,4.8113621 L0.0443960214,0.748684842 C0.0212876636,0.358990526 0.3184643,0.0243478385 0.708158617,0.0012394807 C0.72209045,0.000413341438 0.736043694,2.56373178e-18 0.75,0 Z"
                id="Rectangle"
              ></path>
              <circle id="Oval" cx="0.75" cy="6.75" r="1"></circle>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export { ErrorIcon };
